import { mergeClasses } from '@expo/styleguide';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import { ReactNode } from 'react';

import { BoxWrapper } from '../Box/BoxWrapper';

type Props = PopoverPrimitive.PopoverContentProps & {
  trigger?: ReactNode;
  modal?: boolean;
  /* note(simek): only for tests */
  open?: boolean;
};

export function Popover({
  children,
  className,
  trigger,
  open,
  modal,
  collisionPadding = { left: 16, right: 16 },
  ...rest
}: Props) {
  return (
    <PopoverPrimitive.Root open={open} modal={modal}>
      <PopoverPrimitive.Trigger asChild>{trigger}</PopoverPrimitive.Trigger>
      <PopoverPrimitive.Portal>
        <PopoverPrimitive.Content
          collisionPadding={collisionPadding}
          className={mergeClasses(
            // z-[605] to be above the dialogs (601)
            'z-[605] min-w-[calc(100vw-28px)] max-w-[calc(min(400px,90dvw))] animate-largeSlideDownAndFadeIn outline-0',
            className
          )}
          {...rest}>
          <BoxWrapper className="overflow-hidden rounded-lg shadow-md">{children}</BoxWrapper>
        </PopoverPrimitive.Content>
      </PopoverPrimitive.Portal>
    </PopoverPrimitive.Root>
  );
}
